/**
 * 
 * Pinia Store: pénznem váltás modal ablak allapota
 * 
 * átnevezve: modalauthforgetpassword-re
 * 
*/

export const useModalAuthForgetPasswordStore = defineStore('modalauthforgetpassword', {
  state: () => ({
    isOpened: false,
    prefilledEmail: '',
  }),
  getters: {
    getAuthForgetPasswordModal(): boolean {
      return this.isOpened
    },
  },
  actions: {
    openModal(prefilledEmail = '') {
      this.isOpened = true
      this.prefilledEmail = prefilledEmail
    },
    closeModal() {
      this.isOpened = false
      this.prefilledEmail = ''
    },
  },
})